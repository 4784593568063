









import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PoweredBy extends Vue {
  get poweredBy (): string | null {
    return this.$store.state.powered_by
  }

  get isPoweredBySomeCompany (): boolean {
    return this.poweredBy !== null
  }
}
