












import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuizAnswer } from '../../views/Question.vue'

@Component
export default class HitTheSpot extends Vue {
  @Prop({ type: String }) image!: string
  @Prop({ type: Array, default: () => [] }) answers!: QuizAnswer[]

  width = this.scaleCanvasUnit(400)
  height = this.scaleCanvasUnit(400)
  radius = this.scaleCanvasUnit(30).resized

  imgEl: HTMLImageElement = new Image()
  canvas!: HTMLCanvasElement
  ctx!: CanvasRenderingContext2D | null

  attempts = 0

  givenAnswers = []
  matches: number[] = []

  get total (): number {
    return this.answers.length
  }

  get found (): number {
    return this.matches.length
  }

  mounted () {
    this.canvas = this.$refs.canvas as HTMLCanvasElement
    this.ctx = this.canvas.getContext('2d')
    this.loadImage()
  }

  scaleCanvasUnit (size: number): { resized: number; original: number } {
    return { resized: size * 0.75, original: size }
  }

  loadImage () {
    this.imgEl.onload = () => {
      this.drawImage()
    }

    if (this.image) {
      this.imgEl.src = this.image
    }
  }

  drawImage () {
    if (this.ctx) {
      const { width, height } = this

      this.ctx.drawImage(this.imgEl, 0, 0, width.resized, height.resized)
    }
  }

  handleClick (event: MouseEvent) {
    const rect = this.canvas.getBoundingClientRect()

    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    this.verifyAnswer({ x, y })
  }

  drawAnswer ({ x, y, correct }: { x: number; y: number; correct: boolean }) {
    if (this.ctx) {
      this.ctx.beginPath()
      this.ctx.arc(x, y, this.radius, 0, 2 * Math.PI)
      this.ctx.fillStyle = correct
        ? 'rgba(0, 255, 0, 0.5)'
        : 'rgba(255, 0, 0, 0.5)'
      this.ctx.fill()
      this.ctx.stroke()
      this.ctx.closePath()
    }
  }

  verifyAnswer (givenAnswer: { x: number; y: number }) {
    if (this.total > 0) {
      this.attempts++

      const { width, height, answers } = this

      const givenAnswers = answers.map((answer) => {
        const answerX = answer.x * width.resized / width.original
        const answerY = answer.y * height.resized / height.original

        const hit = Math.sqrt((givenAnswer.x - answerX) ** 2 + (givenAnswer.y - answerY) ** 2) < this.radius

        if (hit) {
          if (this.matches.indexOf(answer.id) === -1) {
            this.matches.push(answer.id)

            this.$emit('found-one', answer.id)
          }
        }

        return hit
      })

      this.drawAnswer({
        x: givenAnswer.x,
        y: givenAnswer.y,
        correct: givenAnswers.some((answer) => answer === true)
      })

      if (this.matches.length > 0) {
        if (this.total === this.matches.length) {
          this.$emit('found-all', this.matches)
        }
      } else {
        this.$emit('found-nothing')
      }
    }
  }
}
