































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import Swal from 'sweetalert2'
import PoweredBy from '../components/PoweredBy.vue'
import HitTheSpot from '../components/questions/HitTheSpot.vue'
import { PREVIEW_COMPONENT } from './Preview.vue'

export type QuizAnswer = {
  id: number;
  nl: string;
  en: string;
  correct: boolean;
  x: number;
  y: number;
  image: string;
}

type QuizQuestion = {
  id: number;
  time: number;
  nl: string;
  en: string;
  background_video: string;
  answers: QuizAnswer[];
  type: 'text' | 'hit' | 'images';
  image: string;
  floor: number;
  theme: string;
  video: string;
  icon: string;
  video_caption: string;
  info_text: string;
  info_image: string;
}

@Component({
  components: {
    PoweredBy,
    HitTheSpot
  }
})
export default class QuestionView extends Vue {
  @Prop({ type: Boolean, default: false }) readonly preview!: boolean;
  @Prop({ type: String, default: null }) readonly questionId!: string;

  isVisible = false

  isQuestionVisible = false

  isFloorHelperVisible = false

  isInfoVisible = false

  isVideoVisible = false

  isVideoLoaded = false

  timeRemaining = 0

  timer?: number

  selectedAnswer = -1

  isAnswered = false

  startTime!: Date

  elapsedTime = 0

  matches: number[] = []

  videoProgress = 0

  async mounted () {
    this.getNextQuestion()
  }

  async beforeDestroy () {
    this.$store.commit('setBackgroundVideo', '')
    this.removeTimer()
  }

  get videoProgressStyle () {
    return {
      width: `${this.videoProgress}%`
    }
  }

  updateVideoProgress (e: Event & { target: HTMLVideoElement }) {
    const { duration, currentTime } = e.target
    this.videoProgress = currentTime * 100 / duration
  }

  get question (): QuizQuestion | null {
    return this.$store.state.question
  }

  get video (): string | null {
    if (this.question && this.question.video) {
      return this.question.video
    }

    return null
  }

  get prize_enabled () {
    return this.$store.state.prize.prize_enabled
  }

  get isTextType (): boolean {
    if (!this.question) return true

    return this.question.type === 'text'
  }

  get isHitType (): boolean {
    if (!this.question) return false

    return this.question.type === 'hit'
  }

  get isImagesType (): boolean {
    if (!this.question) return false

    return this.question.type === 'images'
  }

  get answers (): QuizAnswer[] | null {
    if (this.question === null) return null

    return this.question.answers
  }

  get questionTime (): number | undefined {
    return this.question?.time
  }

  get correctAnswerId () {
    const answer = this.answers?.find((answer) => answer.correct)

    if (answer) return answer.id

    return -1
  }

  get screenPosition () {
    return this.isFloorHelperVisible || this.isVideoVisible
      ? 'justify-center'
      : 'justify-end'
  }

  async showQuestion () {
    const question = this.question

    if (question) {
      const {
        time,
        background_video
      } = question

      if (background_video) {
        this.$store.commit('setBackgroundVideo', background_video)
      }

      this.timeRemaining = time

      this.isVisible = true
      this.isFloorHelperVisible = true
    }
  }

  iconFound () {
    this.isFloorHelperVisible = false

    if (this.question) {
      if (this.question.info_text) {
        this.isInfoVisible = true
      } else {
        this.startQuestion()
      }
    }
  }

  skipInfo () {
    if (this.video) {
      this.startVideo()
    } else {
      this.startQuestion()
    }
  }

  startVideo () {
    this.isFloorHelperVisible = false
    this.isInfoVisible = false
    this.isVideoVisible = true
    this.isQuestionVisible = false
    this.isVisible = true
  }

  startQuestion () {
    this.isFloorHelperVisible = false
    this.isInfoVisible = false
    this.isVideoVisible = false
    this.isQuestionVisible = true
    this.isVisible = true

    this.startTime = new Date()

    this.countdown()
  }

  async getNextQuestion () {
    try {
      if (this.preview) {
        await this.$store.dispatch('loadQuestion', this.questionId)
      } else {
        await this.$store.dispatch('getNextQuestion')
      }

      if (this.question) {
        this.showQuestion()
      }
    } catch (error) {
      if (error.message === 'show-results') {
        this.$router.replace({ name: 'results' })
        return
      }

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.errors,
        onClose: () => {
          this.$router.replace({ name: 'dashboard' })
        }
      })
    }
  }

  countdown () {
    this.timer = window.setInterval(() => {
      if (this.timeRemaining === 0) this.onTimeout()

      if (this.timeRemaining > 0) {
        this.timeRemaining--
      }
    }, 1000)
  }

  isSelected (answerId: number) {
    return this.selectedAnswer === answerId
  }

  onAnswer (answerId: number) {
    if (!this.isAnswered) {
      this.selectedAnswer = answerId

      this.isAnswered = true

      this.onTimeout()
    }
  }

  get selectedLocale (): 'en' | 'nl' {
    return this.$root.$i18n.locale as 'en' | 'nl'
  }

  get questionTextSize () {
    const length = this.questionText.length

    if (length < 80) {
      return 'text-2xl'
    }

    if (length < 120) {
      return 'text-xl'
    }

    return 'text-sm'
  }

  get answerTextSize () {
    let length = 0

    const question = this.question

    if (question) {
      const gtrAnswerLength = question.answers.reduce((gtr, answer) => {
        return Math.max(gtr, answer.en.length)
      }, question.answers[0].en.length)

      length = gtrAnswerLength
    }

    if (length < 30) {
      return 'text-lg'
    }

    if (length < 40) {
      return 'text-base'
    }

    return 'text-sm'
  }

  get questionText () {
    if (this.question === null) return ''

    return this.question.en
  }

  getAnswerText (answer: QuizAnswer) {
    return answer.en
  }

  removeTimer () {
    if (this.timer) {
      window.clearInterval(this.timer)
    }
  }

  async onTimeout () {
    this.removeTimer()

    if (this.elapsedTime === 0) {
      this.elapsedTime = new Date().getTime() - this.startTime.getTime()
    }

    const STORE = true

    if (STORE) {
      setTimeout(() => {
        this.storeAnswer().then(() => {
          if (this.preview) {
            this.$emit('change-preview', PREVIEW_COMPONENT.ANSWER)
          } else {
            this.$router.replace({ name: 'answer' })
          }
        })
      }, 1000)
    }
  }

  foundOne (match: number) {
    this.matches.push(match)
  }

  foundAll (matches: number[]) {
    this.matches = matches

    this.onTimeout()
  }

  foundNothing () {
    this.onTimeout()
  }

  async storeAnswer () {
    const answerId = this.isTextType || this.isImagesType
      ? this.selectedAnswer
      : this.matches

    if (this.preview) {
      let isCorrect = false

      if (Array.isArray(answerId)) {
        isCorrect = answerId.length > 0
      } else {
        if (answerId === -1) {
          isCorrect = false
        } else {
          if (this.answers) {
            const answer = this.answers.find((answer) => answer.id === answerId)

            if (answer) {
              isCorrect = answer.correct
            }
          }
        }
      }

      this.$store.commit('setAnswer', answerId)
      this.$store.commit('setCorrect', isCorrect)
      this.$store.commit('setScore', isCorrect ? 123 : 0)
    } else {
      await this.$store.dispatch('answerQuestion', {
        answer_id: answerId,
        time: this.elapsedTime
      })
    }
  }
}
